/**
 * @author: Ashay Tiwari
 * @date: 6 Sep 2021
 * @description: German Language JSON file
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const TRANSLATION_GERMAN = {
  common: {
    signup: "Sign up",
    signin: "Sign in",
    logOut: "Log out",
    english: "Englisch",
    german: "Deutsch",
    submit: "Submit",
    reset: "Reset",
    back: "Zurück ",
    skip: "Skip",
    next: "Weiter",
    upload: "Upload",
    wantToRegisterAsCompany: "Möchten Sie sich als Firma registrieren?",
    alreadyHaveAnAccount: "Hast du schon ein Account?",
    wantToRegisterAsStudent: "Möchten Sie sich als Student einschreiben?",
    student: "StudentInnen",
    company: "Unternehmen",
    benefitWorld: "Benefitwelt",
    eventWorld: "Eventwelt",
    searchOnHipoz: "Suchen auf hipoZ nach",
    yourFavorites: "Deine Favoriten",
    all: "Alle",
    youMayLike: "Weitere Vorschläge",
    jobsMayLike: "Jobvorschläge für dich",
    studentsMayLike: "KandidatInnen, die dich interessieren könnten",
    yourEvents: "Deine Veranstaltungen",
    seeAll: "Alles sehen",
    about: "Über",
    jobs: "Jobs",
    jobsApplied: "Jobs, für die du dich beworben hast",
    filters: "Filters",
    clearFilters: "Filter löschen",
    yourCandidateList: "Deine KandidatInnenliste",
    postJobs: "Einen neuen Job veröffentlichen",
    subscriptionPlan: "Abonnementplan",
    website: "Unternehmenswebseite",
    post: "Post",
    product: "Produkt",
    event: "Event",
    address: "die Anschrift",
    articles: "Artikel",
    relevant: "Relevant",
    ourEvents: "Unsere Veranstaltungen",
    ourProducts: "Unsere Produkte",
    newest: "Neueste",
    messages: "Nachrichten",
    typeYourMessage: "Geben Sie Ihre Nachricht ein",
    noCompany: "Noch keine Favoriten ausgewählt",
    noResult: "Keine Ergebnisse für deine Suche gefunden.",
    noMember: "Du bist noch kein hipoZ-Member. Bitte kontaktiere das Team, um upgegradet zu werden.",
    noJobs: "Keine passenden Jobs gefunden.",
    noAppliedJobs: "Du hast dich noch für keine Jobs beworben.",
    myProfile: "Mein Profil",
    male: "Männlich",
    female: "Weiblich",
    diversed: "Divers",
    immediate: "Sofort",
    twoWeeks: "2 Wochen",
    oneMonth: "in einem Monat",
    twoMonths: "in drei Monaten",
    threeMonths: "in mehr als drei Monaten",
    intern: "Praktikum",
    fulltime: "Vollzeit",
    partTime: "Teilzeit",
    jan: "Januar",
    feb: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    aug: "August",
    sept: "September",
    oct: "Oktober",
    nov: "November",
    dec: "Dezember",
    basic: "Grundkenntnisse",
    intermediate: "Gute Kenntnisse",
    advance: "Konversationssicher",
    expert: "Fließend in Wort und Schrift",
    native: "Muttersprache",
    readyToWork: "Bereit sofort zu",
    terms: "Bedingungen",
    jobApply: "Jetzt bewerben",
    applyTitle: "Ich bewerbe mich, weil ich ...",
    stayTuned: "Stay tuned! Hier werden alle aktuellen Jobs bei unserem Kick off veröffentlicht!",
    stayTunedStudent: "Stay tuned! Hier werden alle Unternehmen bei unserem Kick off veröffentlicht!",
    stayTunedCompany: "Stay tuned! Hier werden alle Studierenden bei unserem Kick off veröffentlicht!"
  },
  messaging: {
    noChat: "Kein Chatverlauf verfügbar"
  },
  homePage: {
    banner: {
      hipoz: "hipoZ",
      line1: "ist die digitale Zone für Top-StudentInnen,",
      line2: "AbsolventInnen und Young Professionals.",
      line3: "Werde jetzt Teil der Community!",
      highQuality: "Hohe Qualität",
      team: "Mannschaft",
      members: "Mitglieder",
      description:
        "hipoZ ist die digitale Zone für Top-StudentInnen, AbsolventInnen und Young Professionals. Werde jetzt Teil der Community!",
      scrollMore: "Scrollen Sie für mehr"
    },
    benefits: {
      applyFor: "Apply for",
      membership: "membership!",
      description1:
        "Erhalte Zugang zu exklusiven Vorteilen, Einladungen zu hochkarätigen Events und Top-Vorträgen. Vernetze dich mit den Top-ArbeitgeberInnen des Landes!",
      getHipoz: "About us!",
      line1: "hipoZ ist eine Plattform, die ganz auf High Potentials ausgerichtet ist.",
      line2: "hipoZ ist eine Community, die vernetzt, fördert und informiert.",
      line3: "hipoZ ist ein optimales Sprungbrett für eine Top-Karriere.",
      line4: "Aber hipoZ bedeutet auch Spaß, Party und Benefits.",
      line5: "hipoZ ist die Plattform, auf der alle sein wollen, aber nur die Besten reinkommen. ",
      package: "Starterpaket",
      description2:
        "Hipoz ist eine digitale Zone für Top-Studenten, Absolventen und Young Professionals. Erhalten Sie exklusive Vorteile, Einladungen zu hochkarätigen Veranstaltungen und Vorträgen."
    },
    contact: {
      contactUs: "Contact us!",
      student: "Ich bin StudentIn",
      company: "Ich bin ein Unternehmen",
      emailAddress: "eMail-Adresse",
      message: "Nachricht",
      austria: "Populär Werbeagentur GmbH Vienna, Austria",
      hipoz: "hipoZ",
      gmbh: "GmbH"
    }
  },
  login: {
    nameEmailAddress: "Name oder eMail-Adresse",
    yourNameOrEmailAddress: "Dein Name oder eMail-Adresse",
    password: "Passwort",
    yourPassword: "Dein Passwort",
    forgot: "Passwort vergessen?",
    dontHaveAccount: "Hast du noch kein Account?"
  },
  messageConstants: {
    networkError: "Network Error",
    tryAgainLaterError: "Try again later",
    allFieldMandatory: "All fields are mandatory",
    validEmailAddress: "Please enter a valid email address",
    validPhoneNumerAddress: "Please enter a valid mobile number",
    salaryFromShouldBeSmallerThanSalaryTo: "Salary From should be smaller than Salary To",
    salaryShouldBeGreaterThanZero: "Salary should be greater than zero",
    expressInterestFormMandatory: "Fill the form before submission",
    firstEnterSalaryFrom: "Firstly enter salary from",
    mobileNoMustContainNumbers: "Mobile Number should contain Numeric Digits",
    emailIsRequired: "Email is required",
    passwordMismatch: "Your password and confirmation password do not match",
    errorInResetingPass: "Error in reseting password",
    errorInGettingEmail: "Error in getting your email",
    passwordLengthShouldBe8: "Your password must contain atleast 8 characters",
    tokenIsNotAccesible: "Token is not accessible, try again later",
    errorInSubmittingDetails: "Error in submitting details",
    firstlyFillTheseSet: "Please fill these set of details before proceeding",
    fillLastSet: "Please fill last set of details or remove it",
    adminMandatoryField: "Email and Password is required",
    mandatoryDescription: "Please fill the description",
    mandatoryRoleExpectation: "Please fill role expectation",
    mandatoryInterestedWork: "Please fill interested work",
    mandatoryIndustryField: "Please fill industry field",
    mandatryCountryLocation: "Please fill your prefered countries",
    mandatoryJobType: "Please fill your job preferences"
  },
  forgotPassword: {
    forgot: "Passwort vergessen?",
    emailLabel:
      "Wir schicken dir einen Link per eMail zu, mit dem du ein neues Passwort festlegen kannst.",
    email: "Deine eMail-Adresse",
    return: "Zurück zum"
  },
  resetPassword: {
    password: "Neues Passwort eingeben",
    confirmPassword: "Passwort bestätigen",
    resetPassword: "Passwort zurücksetzen",
    returnLogin: "Zurück zum Sign in"
  },
  setPassword: {
    registeredEmail: "Registrierte Email",
    password: "Passwort",
    returnLogin: "Zurück zum Sign in",
    setPassword: "Passwort festlegen"
  },
  studentSignup: {
    page1: {
      firstName: "Vorname",
      yourFirstName: "Dein Vorname",
      lastName: "Nachname",
      yourLastName: "Dein Nachname",
      emailAddress: "eMail-Adresse",
      yourEmailAddress: "Deine eMail-Adresse",
      phNo: "Telefon-/Mobilnummer",
      yourPhNo: "Deine Telefon-/Mobilnummer",
      password: "Passwort",
      yourPassword: "Dein Passwort",
      reenterPassword: "Passwort wiederholen",
      termsAndCond: "Ich stimme den AGB zu"
    },
    page2: {
      photoProfile: "Profil-Foto",
      gender: "Geschlecht",
      dateOfBirth: "Geburtsdatum",
      country: "Land",
      postCode: "Postleitzahl",
      regional: "Region",
      city: "Stadt",
      homeAddress: "Wohnadresse",
      yourGender: "Dein Geschlecht",
      yourPostCode: "Deine Postleitzahl",
      yourRegion: "Deine Region",
      yourCity: "Deine Stadt",
      yourHomeAddress: "Deine Wohnadresse"
    },
    page3: {
      degree: "Abschluss",
      yourDegree: "Dein Abschluss",
      fieldOfStudy: "Studienrichtung",
      yourField: "Deine Studienrichtung",
      university: "Universität/FH",
      yourUniversity: "Deine Universität/FH",
      graduatedOn: "Studiumende",
      courseStarted: "Studienbeginn",
      currentlyStudying: "Ich studiere derzeit hier",
      currentlyWorking: "Ich arbeite derzeit in diesem Unternehmen",
      month: "Monat",
      year: "Jahr",
      addAnother: "Weitere Ausbildungen",
      addAnotherGraduated: "Ergänze Weitere Ausbildungen",
      addAnotherLang: "Weitere Sprachen",
      addAnotherLangPlaceHolder: "Ergänze Weitere Sprachen",
      receivedScholarship:
        "Im Zuge meines Studiums habe ich ein Stipendium bezogen",
      scholarshipType: "Art des Stipendiums",
      yourScholarshipType: "Dein Stipendium",
      scholarshipName: "Name des Stipendiums",
      yourScholarshipName: "Name des Stipendiums",
      workAvailability: "Ich wäre für einen neuen Job ... verfügbar."
    },
    page4: {
      cv: "Lebenslauf",
      jobType: "Welche Art der Anstellung suchst du?",
      yourJobType: "Art der Anstellung",
      languages: "Sprachen",
      yourLanguage: "Deine Sprachen",
      level: "Level",
      yourLanguageLevel: "Dein Sprachlevel",
      specialization: "Fachgebiet",
      yourSpecialization: "Dein Fachgebiet",
      interestedWork: "Ich möchte in folgenden Bereichen arbeiten",
      yourInterestedWork: "Ich möchte in folgenden Bereichen arbeiten",
      industryField: "Branchen",
      yourIndustryField: "Deine Branchen",
      companyLocation: "Standorte",
      yourCOmpanyLocation: "Deine Standorte",
      willingToWorkAbroad: "Ich wäre bereit im Ausland zu arbeiten",
      salaryExpectation: "Gehaltsvorstellungen brutto/Monat",
      yourSalary: "Deine Gehaltsvorstellungen brutto/Monat",
      youExpertiseLevel: "Dein Qualifikationslevel"
    },
    page5: {
      workExp: "Arbeitserfahrung",
      jobType: "Position",
      yourJobType: "Deine Position",
      companyName: "Unternehmen",
      yourCompanyName: "Unternehmensname",
      startWorking: "Ich habe von … in diesem Unternehmen gearbeitet",
      finishWorking: "Ich habe bis ...  in diesem Unternehmen gearbeitet",
      month: "Monat",
      year: "Jahr",
      yes: "Ja",
      no: "Nein",
      removeSet: "Dieses Set entfernen",
      addAnotherWork: "Ergänze weitere Anstellungen",
      studyAbroadExp: "Ich habe im Ausland studiert",
      studyAbroadName: "Auslandsstudium absolviert in",
      yourStudiedAbroadName: "Auslandsstudium absolviert in",
      university: "Universität/FH",
      yourUniversity: "Deine Universität/FH",
      startAbroad: "Ich habe von ... an dieser Universität studiert.",
      finishAbroad: "Ich habe bis ... an dieser Universität studiert.",
      addAnotherStudyAbroad: "Ergänze Weitere Studien im Ausland",
      certificate: "Zeugnisse (optional)",
      uploadCertificates: "Lade deine Zeugnisse hier hoch"
    },
    successScreen: {
      greet: "Vielen Dank!",
      message:
        "Du hast erfolgreich deine Basisdaten übermittelt! Wir prüfen deine Anmeldung und melden uns in Kürze bei dir."
    }
  },
  companySignup: {
    page1: {
      firstName: "Vorname",
      yourFirstName: "Dein Vorname",
      lastName: "Nachname",
      yourLastName: "Dein Nachname",
      gender: "Geschlecht",
      yourGender: "Dein Geschlecht",
      companyName: "Unternehmen",
      yourCompanyName: "Dein Unternehmen",
      position: "Position",
      yourPosition: "Deine Position",
      emailAddress: "eMail-Adresse",
      yourEmailAddress: "Deine eMail-Adresse",
      phNo: "Telefon-/Mobilnummer",
      yourPhNo: "Deine Telefon-/Mobilnummer",
      password: "Passwort",
      yourPassword: "Dein Passwort",
      reenterPassword: "Passwort wiederholen",
      termsAndCond: "Ich akzeptiere die AGBs"
    },
    page2: {
      logo: "Unternehmenslogo",
      desc: "Unternehmensdarstellung",
      yourDesc: "Deine Unternehmensdarstellung",
      philosophy: "Unternehmensphilosophie",
      yourPhilosophy: "Deine Unternehmensphilosophie",
      activity: "Betätigungsfeld",
      yourActivity: "Das Betätigungsfeld deines Unternehmens",
      culture: "Unternehmenskultur",
      yourCulture: "Deine Unternehmenskultur",
      country: "Land",
      regional: "Region",
      yourRegional: "Deine Region",
      city: "Stadt",
      yourCity: "Deine Stadt",
      postcode: "Postleitzahl",
      yourPostCode: "Deine Postleitzahl"
    },
    page3: {
      numberOfEmployees: "Anzahl der Angestellten",
      yourNoOfEmployees: "Deine Anzahl der Angestellten",
      specialization: "Spezialisierung",
      yourSpecialization: "Deine Spezialisierung",
      branche: "Branche",
      yourBranche: "Your Branche",
      branches: "Niederlassungen",
      yourBranches: "Deine Niederlassungen",
      country: "Land",
      city: "Stadt",
      hiring: "Einstellung"
    },
    page4: {
      lookigForHipozWith: "Auf der Suche nach hipoZ mit",
      bachelorDegree: "Bachelor- und Masterabschluss",
      language: "Sprachen",
      level: "Sprachniveau",
      yourLangauge: "Gewünschte Sprache",
      yourLevel: "Gewünschtes Sprachniveau",
      jobOffers: "Stellenangebote",
      yourJobOffers: "Deine Stellenangebote",
      lookingForStaffIn: "Wir suchen Personal in folgenden Bereichen",
      staff: "Dein Personalbedarf",
      fieldOfStudy: "Spezielles Studienfach",
      yourStudies: "Gewünschte Studienfächer",
      university: "Präferierte Universitäten",
      additionalQualifications:
        "Folgende Zusatzqualifikationen sind von Vorteil",
      yourAdvantages: "Gewünschte Zusatzqualifikationen",
      candidateWith: "KandidatInnen mit Erfahrung",
      trainingExp: "Arbeitserfahrung",
      specialization: "Spezialisierungen",
      yourSpecialization: "Gewünschte Spezialisierungen",
      uploadCertificate: "Laden Sie Ihr Zertifikat hoch",
      otherUniversity: "Andere Universität/FH"
    },
    page5: {
      choosePackage: "Wähle ein Paket",
      monthly: "Monatlich",
      yearly: "Jährlich",
      select: "Auswählen",
      selected: "Ausgewählt"
    },
    signupSuccessScreen: {
      greet: "Vielen Dank!",
      message:
        "Ihr Unternehmen hat die grundlegenden Details erfolgreich abgeschlossen, um zu starten! Sie können nun zu Ihrem Profil gehen und Ihr Profil vervollständigen."
    }
  },
  studentProfile: {
    description: "Darstellung",
    editDescription: "Bearbeiten Darstellung",
    roleExpectation: "Gewünschtes Betätigungsfeld",
    editRole: "Bearbeiten Gewünschtes Betätigungsfeld",
    education: "Ausbildung",
    editEducation: "Bearbeiten Ausbildung",
    scholarship: "Stipendium",
    editScholar: "Bearbeiten Stipendium",
    addScholarship: "Stipendium hinzufügen",
    jobType: "Art der Tätigkeit",
    editJobType: "Bearbeiten Art der Tätigkeit",
    language: "Sprachen",
    editLanguage: "Bearbeiten Sprachen",
    addLanguage: "Stipendium Sprachen",
    interestedWork: "Interessiert an",
    editInterested: "Bearbeiten Interessiert an",
    skills: "Qualifikationen",
    editSkills: "Bearbeiten Qualifikationen",
    addSkills: "Weitere Qualifikationen hinzufügen",
    industryField: "Branchen",
    editIndustry: "Bearbeiten Branchen",
    companyLocation: "Präferierte Unternehmensstandorte im Ausland",
    editCompanyLocation: "Bearbeiten Standort des Unternehmens",
    workExp: "Berufserfahrung",
    editWorkExp: "Bearbeiten Berufserfahrung",
    salaryExpectation: "Erwartetes Bruttogehalt pro Monat",
    editSalaryExpectation: "Bearbeiten Erwartetes Bruttogehalt pro Monat",
    addAnotherSalary: "Fügen Sie eine weitere Gehaltsvorstellung hinzu",
    resume: "Lebenslauf",
    editResume: "Bearbeiten Lebenslauf",
    profilePhoto: "Profilfoto"
  },
  sectionMessages: {
    noDescription: "Noch keine Beschreibung hinzugefügt!",
    noEducation: "Noch keine Ausbildung hinzugefügt!",
    noRoleExpectation: "Noch keine Rollenerwartungen hinzugefügt!",
    noScholarship: "Noch kein Stipendium hinzugefügt!",
    noJobType: "Noch kein Jobtyp hinzugefügt!",
    noLanguage: "Noch keine Sprache hinzugefügt!",
    noInterested: "Noch kein interessiertes Werk hinzugefügt",
    noSkills: "Noch keine Fähigkeiten hinzugefügt!",
    noIndustryField: "Noch kein Branchenfeld hinzugefügt!",
    noCompanyLocation: "Noch kein Firmenstandort hinzugefügt!",
    noWorkExp: "Noch keine Berufserfahrung hinzugefügt!",
    noSalary: "Noch keine Gehaltsvorstellung hinzugefügt!",
    noResume: "Noch kein Lebenslauf hochgeladen!",
    noCandidates: "Noch keine Kandidaten verfügbar!",
    noCompanyProduct: "Noch kein Firmenprodukt hinzugefügt!",
    noCompanyCulture: "Noch keine Unternehmenskultur hinzugefügt!",
    noCompanyPhilosophy: "Noch keine Firmenphilosophie hinzugefügt!",
    noCompanyResponsibility: "Noch keine Unternehmensverantwortung hinzugefügt",
    noCompanyLooking: "Noch keine Suche nach hinzugefügt",
    noCompanyBranches: "Noch keine Unternehmensniederlassungen hinzugefügt!",
    noLookingForStaff: "Es wurden noch keine Mitarbeiter gesucht",
    noWorkAbroad: "Noch keine Arbeit im Ausland hinzugefügt",
    noSubscriptionPlan:
      "Noch kein Abonnement abgeschlossen. Holen Sie sich das Abonnement für mehr Vorteile"
  },
  searchEngine: {
    filters: "Fiters",
    clearFilters: "Clear Filters",
    jobField: "Tätigkeitsbereich",
    salary: "Gehalt"
  },
  companyProfile: {
    product: "Unternehmensprodukt",
    editProduct: "Bearbeiten Unternehmensprodukt",
    productLabel:
      "z.B. Produktname. Drücken Sie die Eingabetaste, um den Produktnamen des Unternehmens hinzuzufügen",
    culture: "Unternehmenskultur",
    editCulture: "Bearbeiten Unternehmenskultur",
    philosophy: "Unternehmensphilosophie",
    editPhilosophy: "Bearbeiten Unternehmensphilosophie",
    responsibility: "Betätigungsfeld",
    editResponsibility: "Bearbeiten Betätigungsfeld",
    lookingFor: "Wir suchen Personal, das ...",
    editLooking: "Bearbeiten Wir suchen Personal, das ...",
    companyBranches: "Niederlassungen",
    editCompanyBranches: "Bearbeiten Niederlassungen",
    addAnotherBranch: "Eine weitere Filiale hinzufügen",
    lookingForStaff:
      "Wir sind auf der Suche nach MitarbeiterInnen für folgende Bereiche",
    editLookingForStaff: "Bearbeiten Unternehmen sucht Mitarbeiter",
    lookingForWho:
      "Wir sind auf der Suche nach MitarbeiterInnen, die auch im Ausland arbeiten würden",
    editLookingForWho: "Arbeit im Ausland bearbeiten",
    noJob: "Noch kein Job veröffentlicht!",
    noField: "No field found"
  },
  companyJobPost: {
    jobTitle: "Berufsbezeichnung",
    location: "Standort",
    yourLocation: "Ihre Standortfilialen",
    yourLocationNoBranch:
      "Ihre Standortfilialen (noch keine Filiale hinzugefügt)",
    description: "Darstellung",
    jobDesc: "Arbeitsbeschreibung",
    jobType: "Auftragstyp",
    field: "Gebiet",
    selectField: "Wählen Sie Ihre Branche aus",
    skills: "Fähigkeiten",
    experience: "Erfahrung",
    selectEx: "Wählen Sie die Erfahrungsstufe",
    languages: "Sprachen",
    payRate: "Lohnsatz",
    selectPayRate: "Lohnsatz des Jobs auswählen"
  }
};
